import { useSharedContent } from "~/hooks/localization";
import {
	useLocation,
	useRouteError,
	isRouteErrorResponse,
	useParams,
} from "@remix-run/react";
import { H1 } from "~/components/ui/typography";
import { MarkDown } from "~/components/markdown";
import { type ErrorResponse } from "@remix-run/router";

type StatusHandler = (info: {
	error: ErrorResponse;
	params: Record<string, string | undefined>;
}) => JSX.Element | null;

type GenericErrorBoundaryProps = {
	statusHandlers?: Record<number, StatusHandler>;
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
	defaultStatusHandler?: StatusHandler;
};

export function GenericErrorBoundary({
	statusHandlers = {
		404: () => <FourOhFour />,
	},
	unexpectedErrorHandler = (error) => <ServerError />,
	defaultStatusHandler = ({ error }) => (
		<ErrorPage
			title={`Error - Status: ${error.status}`}
			subtitle={<pre>{error.data}</pre>}
		/>
	),
}: GenericErrorBoundaryProps) {
	const error = useRouteError();
	const params = useParams();

	console.error(error);

	return isRouteErrorResponse(error)
		? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
				error,
				params,
		  })
		: unexpectedErrorHandler(error);
}

export function ErrorPage({
	title,
	subtitle,
}: {
	title: string;
	subtitle: React.ReactNode;
	imgSrc?: string;
}) {
	return (
		<header className="mx-auto flex max-w-content flex-col items-center justify-center px-layout2 py-layout3 text-center md:min-h-[calc(100vh-784px)] md:py-layout5">
			<H1 className="mb-6">{title}</H1>
			{subtitle}
		</header>
	);
}

export function FourOhFour() {
	const { t } = useSharedContent();

	return (
		<ErrorPage
			title={`404 - ${t("errorFourOhFourTitle")}`}
			subtitle={
				<MarkDown
					content={t("errorFourOhFourSubtitle")}
					fullWidth={true}
				/>
			}
		/>
	);
}

function ServerError() {
	const { t } = useSharedContent();
	const location = useLocation();

	return (
		<ErrorPage
			title={t("errorTitle")}
			subtitle={`${location.pathname} ${t(
				"errorSubtitle",
				"is currently unavailable. Please try refreshing the page later."
			)}`}
		/>
	);
}
